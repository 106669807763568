import { createEvent } from 'ics';
import { tzlib_get_ical_block as getIcsVTimezone } from 'timezones-ical-library';
import { dayjs } from '@attendu/utils';

export default ({ event, title, organizer }) => {
  const { value, error } = createEvent({
    productId: 'Attendu',
    title,
    start: dayjs(event.general?.startDate)
      .tz(event.general?.timezone)
      .format('YYYY-MM-DD-HH-mm')
      .split('-')
      .map((item) => Number(item)),
    startOutputType: 'local',
    startInputType: 'local',
    end: dayjs(event.general?.endDate)
      .tz(event.general?.timezone)
      .format('YYYY-MM-DD-HH-mm')
      .split('-')
      .map((item) => Number(item)),
    endOutputType: 'local',
    endInputType: 'local',
    description: '',
    location: event.general?.location?.placeName ? `${event.general.location.placeName}, ${event.general.location.address}, ${event.general.location.city}, ${event.general.location.zipCode}, ${event.general.location.country}` : '',
    organizer,
  });

  if (error) {
    console.error(error);
    return '';
  }

  if (!value) {
    console.error('ics generation failed', event);
  }

  const vTimezone = getIcsVTimezone(event.general?.timezone);
  const valueWithTimezone = value
    .replace('DTSTART:', `DTSTART;${vTimezone?.[1] || ''}:`)
    .replace('DTEND:', `DTEND;${vTimezone?.[1] || ''}:`)
    .replace('BEGIN:VEVENT', `${vTimezone?.[0] || ''}\r\nBEGIN:VEVENT`);

  return valueWithTimezone;
};
