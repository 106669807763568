<template>
  <div
    :style="[
      `--section-primary-color: ${template?.params?.primaryColor}`,
    ]"
    class="py-20 container flex justify-between items-center"
  >
    <div
      class="w-full max-w-[36rem] px-3 flex items-center justify-between mx-auto"
    >
      <div class="w-full border border-gray-200 rounded-xl p-6">
        <h2 class="text-center text-page-xl mb-0.5">
          {{ useT('xyz14', { value: template?.params?.general?.[`_${props.lang}`]?.eventTitle }) }}
        </h2>
        <div class="text-center text-gray-700 text-page-base">
          {{ props.guest.properties?.firstName || '' }} {{ props.guest.properties?.lastName || '' }}
          <template v-if="props.guest?.status?.confirmation > 1">
            (+{{ props.guest?.status?.confirmation - 1 }})
          </template>
        </div>

        <img
          :src="`${useRuntimeConfig().public.imagekit}${useRuntimeConfig().public.imagekit ? '/tr:w-768,c-at_max/' : ''}${useRequestURL().origin?.replace('localhost:3000', 'attendu.dev')}/api/public/guests/${props.guest?._id}/qrcode.png`"
          alt="QR"
          width="384"
          height="384"
          class="max-w-sm h-auto mx-auto"
        >
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  guest: {
    type: Object,
    default: null,
  },
  template: {
    type: Object,
    required: true,
  },
  lang: {
    type: String,
    required: true,
  },
});
</script>
