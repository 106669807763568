<template>
  <div
    :style="[
      `--section-primary-color: ${template?.params?.primaryColor}`,
    ]"
    class="py-20 container flex justify-between items-center"
  >
    <div
      class="w-full max-w-[36rem] px-3 flex items-center justify-between mx-auto"
    >
      <div class="w-full border border-gray-200 rounded-xl p-6">
        <h2 class="text-page-xl mb-0.5">
          {{ useT('xyz1', { value: template?.params?.general?.[`_${props.lang}`]?.eventTitle }) }}
        </h2>
        <div class="text-gray-700 text-page-base mb-4">
          {{ getFormattedDate(
            {
              start: props.event?.general?.startDate,
              end: props.event?.general?.endDate,
            },
            {
              lang: props.lang,
              format: 'lll',
              timezone: props.event?.general?.timezone,
            },
          ) }}
          <template v-if="props.event?.general?.location?.placeName && !props.event?.general?.isOnlineOnly">
            •
            {{ props.event.general.location.placeName }}
          </template>
        </div>

        <Button
          type="regular-outline"
          icon-left="calendar"
          @click="addToCalendar"
        >
          {{ useT('xyz701') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  event: {
    type: Object,
    required: true,
  },
  guest: {
    type: Object,
    default: null,
  },
  template: {
    type: Object,
    required: true,
  },
  lang: {
    type: String,
    required: true,
  },
});

// Methods
const addToCalendar = () => useCalendar().exportICS({
  event: {
    ...props.event,
    general: {
      ...props.event.general,
      startDate: props.guest?.slot?.startDate || props.event.general.startDate,
      endDate: props.guest?.slot?.endDate || props.event.general.endDate,
    },
  },
  title: props.template?.params?.general?.[`_${props.lang}`]?.eventTitle,
});
</script>
