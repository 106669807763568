import getIcs from '../api/shared/_get-ics.js';

export default () => {
  return {
    exportICS: ({ event, title }) => {
      const ics = getIcs({ event, title });
      const file = new Blob([ics], { type: 'text/calendar;charset=utf-8' });
      const url = URL.createObjectURL(file);

      window.location.href = url;
    },
  };
};
